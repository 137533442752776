import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';

const ExitPopupWebMobileTitleComponent = ({ namespace, title }) => (
  <div className={classnames(`${namespace}__div-modal-title`)}>
    <div className={classnames(`${namespace}__div-exit-popup-title`)}>
      <p className={classnames(`${namespace}__title`)}>{title}</p>
    </div>
  </div>
);

ExitPopupWebMobileTitleComponent.propTypes = {
  namespace: string.isRequired,
  title: string.isRequired,
};

ExitPopupWebMobileTitleComponent.defaultProps = {
  namespace: 'ui-pdp-exit-popup-web-mobile',
  title: '',
};

export default ExitPopupWebMobileTitleComponent;
