export const getOffsetIndex = (index, bulkSale, yieldValue, offset, quantityValue, hasOptions) => {
  if (hasOptions) {
    return bulkSale ? index * yieldValue : offset + index;
  }
  if (quantityValue) {
    const bulkCustomOptionsOffsetIndex = yieldValue * quantityValue;
    return bulkSale ? bulkCustomOptionsOffsetIndex : quantityValue;
  }
  return index;
};
