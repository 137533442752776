import { loadable } from 'nordic/lazy';
import get from 'lodash/get';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FullscreenAd = loadable(() => import('../../../../components/advertising'));

const FEATURE_NAME = 'fullscreen_ad';
const className = 'ui-fullscreen-ad-vip';

const mapStateToProps = (
  {
    components: {
      [FEATURE_NAME]: {
        google_ad: googleAd,
        slot_id: slotId,
        caption,
        segmentation,
        viewport_margin: viewportMargin,
        personal_data_ads_denied,
        track,
      },
    },
  },
  ownProps,
) => ({
  slotId,
  viewportMargin,
  personalDataAdsDenied: personal_data_ads_denied,
  googleAd: {
    ...googleAd,
  },
  segmentation: {
    custID: segmentation.cust_id,
    Posiciones: segmentation.position,
    platform: segmentation.platform,
    CAR_BRAND: segmentation ? segmentation.brand : '',
    Model: segmentation ? segmentation.model : '',
    VEHICLE_YEAR: segmentation ? segmentation.year : '',
    ITEM_CONDITION: segmentation ? segmentation.item_condition : '',
    businessUnit: segmentation ? segmentation.business_unit : '',
  },
  caption,
  className,
  melidataEvent: get(track, 'melidata_event', null),
  ...ownProps,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(FullscreenAd);
