import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import ExtendedMenu from '@seo-frontend-components/extended-menu-mobile';

const namespace = 'ui-pdp-extended-menu';

const ExtendedMenuMobile = ({ menu, title, runCatchErrorBoundary }) => {
  try {
    return (
      menu?.length > 0 && (
        <div className={namespace}>
          <Card>
            <CardContent>
              <ExtendedMenu title={title} menu={menu} />
            </CardContent>
          </Card>
        </div>
      )
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

ExtendedMenuMobile.namespace = namespace;

ExtendedMenuMobile.propTypes = {
  title: string.isRequired,
  menu: arrayOf(
    shape({
      title: string.isRequired,
      url: string,
      children: arrayOf(
        shape({
          text: string.isRequired,
          url: string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  runCatchErrorBoundary: func,
};

ExtendedMenuMobile.defaultProps = {
  classNames: '',
  menu: [],
  runCatchErrorBoundary: () => {},
};

export default ExtendedMenuMobile;
