import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';

const RecommendationsListDouble = loadable(() => import('../../../../components/recommendations/list-double'));

const enhance = compose(
  withFeatureFlag(),
  connect(
    mapStateToProps(),
    null,
    'recos_list_double',
  ),
);

export default enhance(RecommendationsListDouble);
