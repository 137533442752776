import React from 'react';
import { Button } from '@andes/button';
import { quantitytInputButtonPropTypes } from '../model';

const QuantitytInputButton = ({ className = '', disabled = false, onClick = null, confirm = '' }) => (
  <Button className={className} onClick={onClick} disabled={disabled}>
    {confirm}
  </Button>
);

QuantitytInputButton.propTypes = quantitytInputButtonPropTypes;

export default QuantitytInputButton;
