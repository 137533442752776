import { useState, useEffect, startTransition } from 'react';
import { trackEvent } from '@vpp-frontend-components/common';
import HistoryService from '../../../../services/history';
import { runSchedulerTask } from '../../../../utils/validators';
import customOptions from '../../../../utils/quantity-custom-options';
import { parseBulkQuantity } from '../../../../utils/formatBulkQuantity';

const useQuantityMobile = ({ quantity_selector, picker, onSetQuantity, isFetching }) => {
  const yieldValue = quantity_selector?.selector?.template?.yield;
  const bulkSale = yieldValue != null && yieldValue > 0;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const initialQuantity = bulkSale ? picker?.selected * yieldValue : Number(picker?.selected) || null;
  const [localQuantity, setLocalQuantity] = useState(initialQuantity);
  const { custom_options } = quantity_selector?.selector?.template ?? {};
  const hasCustomOptions = customOptions.has(custom_options);

  useEffect(() => {
    if (isOpenModal) {
      trackEvent(picker?.track);
    }
  }, [isOpenModal, picker?.track]);

  useEffect(() => {
    if (hasCustomOptions) {
      setLocalQuantity(initialQuantity);
    }
  }, [initialQuantity, hasCustomOptions]);

  const onActionRowClick = e => {
    e.preventDefault();
    setIsOpenModal(!isFetching);
  };

  const showErrorMessage = bulkSale && picker && picker.error_message && !picker.error_message.is_deferred;
  /* istanbul ignore next */
  const handleOnSetQuantity = quantity => {
    let totalQuantity = quantity;

    if (bulkSale) {
      totalQuantity = parseBulkQuantity(quantity, yieldValue);
    }

    setLocalQuantity(quantity);
    startTransition(() => {
      onSetQuantity(totalQuantity);
      setIsOpenModal(false);
      runSchedulerTask(() => {
        HistoryService.pushParam('quantity', totalQuantity, true);
      }, 'background');
    });
  };

  return {
    isOpenModal,
    setIsOpenModal,
    localQuantity,
    bulkSale,
    hasCustomOptions,
    showErrorMessage,
    handleOnSetQuantity,
    onActionRowClick,
  };
};

export default useQuantityMobile;
