import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import { withBoxComponent, MODAL } from '../../../../components/box-component/with-box-component.mobile';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const Box = loadable(() => import('../../../../components/box-component/box'));

const FEATURE_NAME = 'warranty';

const Warranty = withBoxComponent(MODAL)(Box);

const mapStateToProps = ({ components: { [FEATURE_NAME]: warranty } }) => ({ ...warranty });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Warranty);
