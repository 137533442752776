import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import Breadcrumb from '../../../../components/breadcrumb';

const FEATURE_NAME = 'breadcrumb';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }, { mode }) => ({ ...props, mode });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Breadcrumb);
