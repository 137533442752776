import React from 'react';
import classnames from 'classnames';
import { bool, string, shape, func } from 'prop-types';
import { CardTooltipSkeleton } from '../../placeholder';
import Banner from './banner.desktop';

const namespace = 'ui-pdp-banner-mobile';

const BannerMobile = ({ runCatchErrorBoundary, ...props }) => {
  try {
    const deviceType = 'mobile';
    const { isFetching, picture, className } = props;
    if (isFetching) {
      return (
        <CardTooltipSkeleton
          hasIcon
          icon
          hasProgressBar
          height={picture.height}
          className={`${namespace}-skeleton mt-12`}
        />
      );
    }

    return <Banner {...props} deviceType={deviceType} className={classnames(namespace, className)} />;
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

BannerMobile.propTypes = {
  id: null,
  className: string,
  picture: shape({}),
  accessibility_text: null,
  isFetching: bool,
  runCatchErrorBoundary: func,
};

BannerMobile.defaultProps = {
  action: null,
  className: null,
  information_tooltip: null,
  picture: null,
  id: null,
  isFetching: false,
  remaining: null,
  subtitles: null,
  text: null,
  custom_message: null,
  tooltip: null,
  runCatchErrorBoundary: () => {},
};
export default BannerMobile;
