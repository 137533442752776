import { arrayOf, string, number, shape, func, bool, node, object } from 'prop-types';

export const namespace = 'ui-pdp-buybox__quantity';

export const quantityListPropTypes = {
  label: shape({
    plural: string,
    singular: string,
    options: arrayOf(
      shape({
        color: string,
        text: string,
        values: shape({}),
      }),
    ),
  }).isRequired,
  length: number.isRequired,
  yieldValue: number,
  customOptions: arrayOf(
    shape({
      quantity: shape({
        text: string,
        value: number,
        values: shape({
          equivalence: shape({
            text: string,
            color: string,
            font_size: string,
          }),
        }),
      }),
      amount: shape({
        fraction: string,
        cents: string,
        suffix: shape({
          text: string,
        }),
      }),
    }),
  ),
  min: number.isRequired,
  max: number.isRequired,
  more: string,
  selected: number.isRequired,
  onClick: func.isRequired,
  onShowInput: func.isRequired,
  hasBulkCustomOptions: bool,
};

// QuantityListItem
export const quantityListItemPropTypes = {
  index: number.isRequired,
  offsetIndex: number.isRequired,
  isSelected: bool.isRequired,
  max: number.isRequired,
  onQuantityClick: func.isRequired,
  customOptions: shape({
    quantity: shape({
      text: string,
      value: number,
      values: shape({
        equivalence: shape({
          text: string,
          color: string,
          font_size: string,
        }),
      }),
    }),
    amount: shape({
      fraction: number,
      cents: number,
      suffix: shape({
        text: string,
      }),
      currency_id: string,
    }),
  }),
  labelElement: node.isRequired,
};

// QuantityMoreButton
export const quantityMoreButtonPropTypes = {
  more: node.isRequired,
  onShowInput: func.isRequired,
  customOptions: object,
};
