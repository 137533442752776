import React, { useContext } from 'react';
import formatBulkQuantity from '../../../utils/formatBulkQuantity';
import classnames from 'classnames';
import ActionRow from '../../action-row';
import BackdropModal from '../../backdrop-modal';
import StaticPropsContext from '../../context/static-props';
import QuantitySelector from './components/quantity-selector/quantity-selector.mobile';
import QuantityNative from './components/quantity-native/quantity-native';
import ErrorMessage from '../../variations/components/error-message';
import StyledLabel from '../../styled-label';
import Quantity from './quantity';

import { quantityMobilePropTypes } from './model';
import useQuantityMobile from './hooks/use-quantity-mobile';

const namespace = 'ui-pdp-buybox__quantity';

const QuantityMobile = ({
  className,
  quantity_selector,
  picker,
  type,
  label,
  showInput = false,
  showQuantityInput = false,
  onSetQuantity,
  onShowInput,
  isFetching = false,
  closeModalLabel,
  runCatchErrorBoundary = () => {},
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { lowEnd } = useContext(StaticPropsContext);
    const {
      isOpenModal,
      setIsOpenModal,
      localQuantity,
      bulkSale,
      hasCustomOptions,
      showErrorMessage,
      handleOnSetQuantity,
      onActionRowClick,
    } = useQuantityMobile({
      quantity_selector,
      picker,
      onSetQuantity,
      isFetching,
    });

    if (type === 'label_component') {
      return (
        <Quantity className={className} picker={picker}>
          <StyledLabel {...label} />
        </Quantity>
      );
    }

    if (picker) {
      if (lowEnd) {
        return (
          <QuantityNative
            className={`${namespace}__lowend--mobile`}
            message={picker.description}
            quantity={localQuantity}
          />
        );
      }

      return (
        <Quantity className={className} picker={picker}>
          <ActionRow
            className={classnames(namespace)}
            label={picker.title}
            selected={picker.selected_label || !!localQuantity}
            subtitle={bulkSale ? null : picker.description}
            onClick={onActionRowClick}
            disabled={!quantity_selector}
            modifier={picker.border_color ? 'error' : null} // @TODO - Map from border color to modifier, something like colorClassnames for fonts but for actionrows
          />
          {showErrorMessage && (
            <span className={`${namespace}__alert`}>
              {' '}
              <ErrorMessage error={picker.error_message} />
            </span>
          )}
          {quantity_selector ? (
            <BackdropModal
              className={classnames({ [`backdrop-modal__custom-options`]: hasCustomOptions })}
              onClose={() => {
                setIsOpenModal(false);
                onShowInput(false);
              }}
              visible={isOpenModal}
              closeModalLabel={closeModalLabel}
            >
              <QuantitySelector
                selected={bulkSale ? formatBulkQuantity(localQuantity) : localQuantity}
                showInput={showQuantityInput || showInput}
                setQuantity={handleOnSetQuantity}
                onShowInput={onShowInput}
                quantitySelector={quantity_selector}
                description={bulkSale ? picker.description : null}
              />
            </BackdropModal>
          ) : null}
        </Quantity>
      );
    }
    return null;
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

QuantityMobile.propTypes = quantityMobilePropTypes;

export default QuantityMobile;
