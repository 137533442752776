import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/gallery-with-plugins/helpers/json-to-props';
import withPlugins from '../../../../utils/with-plugins';
import {
  MainMobile as Gallery,
  PluginCoreMobile as corePlugin,
  PluginClipsMobile as clipsPlugin,
  PluginBookmarkMobile as bookmarkPlugin,
  PluginShareMobile as sharePlugin,
  PluginVisMobile as visPlugin,
  withGalleryProvider,
} from '../../../../components/gallery-with-plugins';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'gallery_octopus';
const GALLERY = 'gallery';
const SHARE = 'share';
const BOOKMARK = 'bookmark';
const WISHLIST_SAVE_BUTTON = 'wishlist_save_button';

const mapStateToProps = state => {
  const {
    [GALLERY]: gallery,
    [SHARE]: share,
    [BOOKMARK]: bookmark,
    [WISHLIST_SAVE_BUTTON]: wishlist,
    header: { title },
  } = state.components;

  const { csrfToken } = state;

  return jsonToProps({
    bookmark,
    csrfToken,
    share,
    ...gallery,
    titleForAlt: title,
    app: 'vip',
    wishlist,
  });
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  removeElementFromList: element => dispatch(actions.removeElementFromList(element)),
  addElementToList: element => dispatch(actions.addElementToList(element)),
  onClickBookmark: e => {
    e.preventDefault();
    return dispatch(actions.toggleBookmark());
  },
  showSnackbar: props => dispatch(actions.showSnackbar({ ...props })),
  openWishlistModalBS: () => dispatch(actions.openWishlistModalBS()),
  closeWishlistModalBS: isBookmarked => dispatch(actions.closeWishlistModalBS(isBookmarked)),
  updateGiftRegistry: check => dispatch(actions.fetchUpdatedWishlists(check)),
  toggleGiftRegistryCheckbox: check => dispatch(actions.toggleGiftRegistryCheckbox(check)),
  fetchBookmark: () => dispatch(actions.fetchBookmark()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: 'components.gallery' }),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withGalleryProvider,
  withPlugins(corePlugin, clipsPlugin, visPlugin, bookmarkPlugin, sharePlugin)({
    debugMode: false,
    namespace: 'ui-pdp-gallery',
    featureName: FEATURE_NAME,
  }),
);

export default enhance(Gallery);
