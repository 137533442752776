import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'apparel_size_specs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: apparelSizeSpec } }) => apparelSizeSpec;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const apparelSizeSpec = ApparelSizeSpec => enhance(ApparelSizeSpec);

export default apparelSizeSpec;
