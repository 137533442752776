/* eslint-disable react/prop-types */

import React, { useState, startTransition } from 'react';

import { ComponentRenderer } from '../../../../../../../utils/with-plugins';

const Main = props => {
  const { getHandlers, list, componentsToRender, handleError, metrics, wrapperConfig } = props;

  const [activeModalTab, setActiveModalTab] = useState();
  const [modalVisibility, setModalVisibility] = useState(false);

  const handlers = getHandlers({
    setModalVisibility,
    startTransition,
  });

  return (
    <ComponentRenderer
      list={list}
      componentsToRender={componentsToRender}
      handleError={handleError}
      metrics={metrics}
      wrapperConfig={wrapperConfig}
      calculatedProps={{
        activeModalTab,
        setActiveModalTab,
        modalVisibility,
        setModalVisibility,
        handlers,
      }}
    />
  );
};

export default Main;
