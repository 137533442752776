const initializeHydration = (Core, bootstrap) => {
  let hydrated = false;

  const run = () => {
    hydrated = true;
    requestAnimationFrame(() => setTimeout(() => bootstrap(Core), 0));
  };

  const banner = document.getElementsByClassName('download-app-bottom-banner-wrapper')[0];

  if (!banner) {
    run();
  } else {
    const mo = new MutationObserver(() => {
      if (!hydrated) {
        run();
      }
      mo.disconnect();
    });
    mo.observe(banner, { attributes: true, childList: true, subtree: true });
  }

  setTimeout(() => {
    if (!hydrated) {
      run();
    }
  }, 10000);
};

export default initializeHydration;
