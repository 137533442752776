import React, { useState } from 'react';
import { shape, string, bool, func } from 'prop-types';
import classNames from 'classnames';
import colornames from '../../lib/colornames';
import { Label } from '../../prop-types';
import StyledLabel from '../styled-label';
import TooltipLegal from '../tooltip-legal/tooltip-legal.mobile';
import IconFactory from '../icons/factory-builder';

const namespace = 'ui-pdp-stock-information';

const StockInformation = ({ collapsible_tooltip, className, title, subtitle, runCatchErrorBoundary }) => {
  try {
    const [showMOQTooltip, setShowMOQTooltip] = useState(false);

    const handleShowTooltip = e => {
      e.preventDefault();
      if (!showMOQTooltip) {
        setShowMOQTooltip(true);
      }
    };
    return (
      <div className={classNames(namespace, className)}>
        <StyledLabel className={`${namespace}__title`} {...title} />
        <div className={`${namespace}__container`}>
          {subtitle && <StyledLabel className={`${namespace}__subtitle`} {...subtitle} />}
          {collapsible_tooltip && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <span
              data-testid="info-icon"
              className={`${namespace}__container__info-icon`}
              onClick={e => handleShowTooltip(e)}
              role="button"
              tabIndex="-1"
            >
              {IconFactory(collapsible_tooltip.icon.id, colornames(collapsible_tooltip.icon.color))}
            </span>
          )}
        </div>
        {collapsible_tooltip && (
          <TooltipLegal
            collapsible_tooltip={collapsible_tooltip}
            showMOQTooltip={showMOQTooltip}
            setShowMOQTooltip={setShowMOQTooltip}
          />
        )}
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

StockInformation.propTypes = {
  collapsible_tooltip: shape({
    icon: shape({
      id: string,
      color: string,
      size: string,
    }),
    opened: bool,
    tooltip: shape({
      arrow_type: string,
      background_color: string,
      change_color: bool,
      close_color: string,
      closeable: bool,
      closed: bool,
      has_shadow: bool,
      state: string,
      storable: bool,
      subtitles: shape([
        {
          color: string,
          font_family: string,
          font_size: string,
          text: string,
        },
      ]),
      title: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      type: string,
    }),
  }),
  className: string,
  title: Label.isRequired,
  subtitle: Label,
  runCatchErrorBoundary: func,
};

StockInformation.defaultProps = {
  className: '',
  subtitle: null,
  runCatchErrorBoundary: () => {},
};

export default StockInformation;
