import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const MerchLeftSideVipMobile = loadable(() =>
  import('../../../../components/merch-ecosystem/merch-ecosystem.vip.mobile'),
);

const FEATURE_NAME = 'merch_box';

const mapStateToProps = ({ components: { [FEATURE_NAME]: merch } }, ownProps) => ({
  ...ownProps,
  ...merch,
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(MerchLeftSideVipMobile);
