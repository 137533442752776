import React from 'react';
import { arrayOf, bool, string, shape, func, oneOfType, node, number } from 'prop-types';
import CouponSummary from './coupons';
import classnames from 'classnames';
import CarouselPills from './_coupon-carousel-pills';
import AwarenessRow from '../coupons-awareness/_awareness-row';
import handleAwarenessInteraction from './_handle-awareness-interaction';

const namespace = 'ui-vpp-coupons';

const CouponsMobile = ({
  action,
  className,
  onCouponUpdate,
  onCouponActivate,
  showSnackbar,
  icon,
  subtitles,
  title,
  coupon_tags,
  isFetching,
  id,
  snackbar,
  awareness,
  runCatchErrorBoundary,
}) => {
  try {
    const actionModalRef = React.useRef(null);

    const openModal = () => {
      actionModalRef?.current?.openModal();
    };

    return (
      <CouponSummary
        {...{
          action,
          className: classnames(className, {
            [`${namespace}__awareness`]: awareness,
          }),
          onCouponUpdate,
          showSnackbar,
          coupon_tags,
          icon,
          subtitles,
          title,
          id,
          snackbar,
          device: 'mobile',
          handleOnSubtitleClick: () =>
            handleAwarenessInteraction({
              onCouponActivate,
              openModal,
              awareness,
              actionTarget: action?.target,
              isFetching,
            }),
          actionModalRef,
        }}
      >
        {awareness ? (
          <AwarenessRow
            {...{
              className: namespace,
              awareness,
              pills: coupon_tags,
              onCouponActivate: () =>
                handleAwarenessInteraction({
                  onCouponActivate,
                  openModal,
                  awareness,
                  actionTarget: action?.target,
                  isFetching,
                }),
              showSnackbar,
              id: 'coupons-pills',
            }}
          />
        ) : (
          <CarouselPills
            {...{
              className: `${namespace}__carousel`,
              pills: coupon_tags,
              pillOnClick: openModal,
              id: 'carousel-pills',
            }}
          />
        )}
      </CouponSummary>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

CouponsMobile.propTypes = {
  coupon_tags: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string,
      onClick: func,
    }),
  ),
  snackbar: shape({}),
  awareness: shape({}),
  onCouponUpdate: func.isRequired,
  onCouponActivate: func,
  showSnackbar: func,
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: shape({}).isRequired,
  id: string.isRequired,
  runCatchErrorBoundary: func,
};

CouponsMobile.defaultProps = {
  action: null,
  className: null,
  subtitles: null,
  icon: null,
  isFetching: null,
  onCouponUpdate: null,
  showSnackbar: null,
  snackbar: null,
  runCatchErrorBoundary: () => {},
};

export default React.memo(CouponsMobile);
