import React from 'react';
import QuantityInput from '../quantity-input/quantity-input';
import QuantityList from '../quantity-list/quantity-list';
import customOptions from '../../../../../utils/quantity-custom-options';
import { namespaceMobile as namespace, quantitySelectorPropTypes } from './model';
import QuantityListInputButton from './mobile/quantity-input-button';
import QuantityStyledLabels from './mobile/quantity-styled-labels';
import useQuantitySelectorMobile from '../../hooks/use-quantity-selector-mobile';

const QuantitySelector = ({ showInput, selected, setQuantity, quantitySelector, onShowInput }) => {
  const {
    onSetQuantity,
    minimumQuantity,
    bulkSale,
    yieldValue,
    hasCustomOptions,
    hasBulkCustomOptions,
    custom_options,
  } = useQuantitySelectorMobile(quantitySelector, setQuantity);

  let title = quantitySelector.selector.title.text;
  if (showInput) {
    title = bulkSale ? quantitySelector?.selector?.template.title : title;
  }

  const quantityListLength = hasCustomOptions ? custom_options.length : quantitySelector?.selector?.rows;
  const quantityListCustomOptions = hasCustomOptions
    ? customOptions.getItemsFormatted(custom_options, quantitySelector?.selector?.template?.site_code)
    : quantitySelector?.selector?.template?.volume_discount_options;

  const maxAvailableQuantity =
    bulkSale || hasBulkCustomOptions
      ? quantitySelector.available_quantity * yieldValue
      : quantitySelector.available_quantity;

  return (
    <>
      <h4 className={`${namespace}__title`}>{title}</h4>
      {showInput ? (
        <QuantityInput
          confirm={quantitySelector.input.confirm_button.label.text}
          button={QuantityListInputButton}
          length={quantitySelector.selector.rows}
          noStockMessage={quantitySelector.input.error_message}
          minErrorMessage={quantitySelector.input.min_error_message}
          quantity={selected}
          subtitles={quantitySelector.input.subtitles}
          setQuantity={onSetQuantity}
          bulkSale={bulkSale || hasBulkCustomOptions}
          min={minimumQuantity}
          max={maxAvailableQuantity}
          placeholder={quantitySelector.input.placeholder}
          selectorTemplate={quantitySelector?.selector?.template}
          mobile
        />
      ) : (
        <>
          {quantitySelector.selector.subtitles && (
            <QuantityStyledLabels subtitles={quantitySelector.selector.subtitles} />
          )}
          <QuantityList
            length={quantityListLength}
            label={quantitySelector?.selector?.template}
            min={minimumQuantity}
            max={maxAvailableQuantity}
            yieldValue={bulkSale || hasBulkCustomOptions ? yieldValue : null}
            more={quantitySelector?.selector?.template?.more}
            selected={selected}
            onClick={onSetQuantity}
            onShowInput={onShowInput}
            customOptions={quantityListCustomOptions}
            hasBulkCustomOptions={Boolean(yieldValue) && hasCustomOptions}
          />
        </>
      )}
    </>
  );
};

QuantitySelector.propTypes = quantitySelectorPropTypes;

export default QuantitySelector;
