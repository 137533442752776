import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const MoqInformation = loadable(() => import('../../../../components/moq-information/moq-information.mobile'));

const FEATURE_NAME = 'moq_information';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => ({ ...props });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(MoqInformation);
