import React from 'react';
import StyledLabel from '../../../../../styled-label';
import { generateKeyToComponent } from '../../../../../../utils/validators';
import componentEnhance from '../../../../../../lib/component-enhance';
import { namespaceMobile as namespace, quantityStyledLabelsPropTypes } from '../model';

const QuantityStyledLabels = ({ subtitles }) => (
  <>
    {subtitles.map(subtitle => (
      <StyledLabel
        key={generateKeyToComponent(subtitle)}
        className={`${namespace}__subtitle`}
        as="p"
        {...subtitle}
        text={componentEnhance.jsx(subtitle.text, subtitle.values)}
      />
    ))}
  </>
);

QuantityStyledLabels.propTypes = quantityStyledLabelsPropTypes;

export default QuantityStyledLabels;
