import componentEnhance from '../../../../lib/component-enhance';

export const getLabelElement = (label, index, hasOptions, hasBulkCustomOptions, customOptions) => {
  if (hasOptions) {
    const { options } = label;
    return componentEnhance.jsx(options[index - 1].text, options[index - 1].values);
  }

  if (hasBulkCustomOptions && customOptions) {
    return componentEnhance.jsx(
      customOptions?.[index - 1]?.quantity?.text,
      customOptions?.[index - 1]?.quantity?.values,
    );
  }

  if (customOptions) {
    return componentEnhance.jsx(customOptions?.[index - 1]?.quantity?.text);
  }

  // @TODO replace with interpolation helper
  return label[index === 1 ? 'singular' : 'plural'].replace('{quantity}', index);
};
