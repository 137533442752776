import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { string, shape, func } from 'prop-types';
import classnames from 'classnames';
import BaseModal from '../modals/base-modal/index';
import { trackEventSync } from '../../lib/tracking';
import ExitPopupWebMobileTitle from './exit-popup-web-mobile-title';
import { Button } from '@andes/button';
import Close from '@andes/icons/Close24';
import { setCookie } from '../../lib/cookies';
import RecommendationsListDouble from '../../pages/vip/features/recommendations/list-double';

const namespace = 'ui-pdp-exit-popup-web-mobile';
const name_history_push = 'exit-popup-push-history';
const cookie_name = 'ml_gads_exit-popup-offer';

const ExitPopupWebMobileComponent = ({ url, state, track, carousel_config, runCatchErrorBoundary }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { title, subtitle = {} } = carousel_config?.recommendation_from_middle_end;
    const isTrackHidden = state === 'TRACK_HIDDEN';
    const isHidden = state === 'HIDDEN';

    const [openModal, setOpenModal] = useState(false);
    const [sendTrack, setSendTrack] = useState(false);

    const cookie_attributes = useMemo(
      () => ({
        path: '/',
        domain: `.${new URL(url).hostname
          .split('.')
          .slice(-3)
          .join('.')}`,
      }),
      [url],
    );

    const send_track = trackParam => {
      setSendTrack(true);
      if (trackParam.melidata_event !== undefined) {
        trackParam.melidata_event.useBeacon = true;
      }
      trackEventSync(trackParam);
    };

    const getDateNow = () => {
      const dateNow = new Date();
      return dateNow.toISOString();
    };

    const waitSetTimeout = () =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 50);
      });

    const handlePopState = useCallback(async event => {
      if (!sendTrack && event.state !== name_history_push) {
        if (!isTrackHidden) {
          setOpenModal(true);
        }
        setCookie(cookie_name, getDateNow(), cookie_attributes);
        send_track(track);
        await waitSetTimeout();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendTrack, isTrackHidden, track, cookie_attributes]);

    const CloseModal = () => {
      setOpenModal(false);
      window.history.back();
    };

    useEffect(() => {
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [handlePopState]);

    useEffect(() => {
      if ((!isTrackHidden || !isHidden) && window.history.state !== name_history_push) {
        window.history.pushState(name_history_push, null, window.location.href);
      }
    }, [isTrackHidden, isHidden]);

    if (isTrackHidden || isHidden) {
      return null;
    }

    return (
      <BaseModal
        key={`${namespace}__modal-${Math.random()}`}
        id={`${namespace}__modal`}
        isOpen={openModal}
        onClose={() => CloseModal()}
        closable={false}
        type="large"
        autoHeight={false}
        className={classnames(`${namespace}__bg-modal-exit-popup`)}
      >
        <div className={classnames(`${namespace}__div-modal-container`)}>
          <div className={classnames(`${namespace}__div-close-button-container`)}>
            <Button onClick={() => CloseModal()} style={{ padding: 0 }} hierarchy="transparent" className="active">
              <Close />
            </Button>
          </div>
          <>
            <ExitPopupWebMobileTitle title={title} namespace={namespace} />
          </>
          <div className={classnames(`${namespace}__div-modal-list-container`)}>
            <RecommendationsListDouble
              featureName="carousel_exit_pop_up"
              className={`${namespace}-recommendations-list`}
            />
          </div>
          <div className={classnames(`${namespace}__div-modal-button`)}>
            <Button hierarchy="loud" className={classnames(`${namespace}__exit-popup-button`)} href={`${subtitle.url}`}>
              {subtitle.text}
            </Button>
          </div>
        </div>
      </BaseModal>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

ExitPopupWebMobileComponent.propTypes = {
  url: string.isRequired,
  state: string.isRequired,
  track: shape({ melidata_event: shape({}) }),
  carousel_config: shape({ recommendation_from_middle_end: shape({ title: string, subtitle: shape({}) }) }),
  runCatchErrorBoundary: func,
};

ExitPopupWebMobileComponent.defaultProps = {
  isOpen: true,
  url: '',
  state: '',
  carousel_config: { recommendation_from_middle_end: { title: '', subtitle: {} } },
  runCatchErrorBoundary: () => {},
};

export default ExitPopupWebMobileComponent;
