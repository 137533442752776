import React from 'react';
import { loadable } from 'nordic/lazy';
import RecommendationsIconFactory from './icon-factory';
import getCombos from './combos';

const RecommendationsCombosMobile = loadable(() => import('./recos-combo'));

const Component = props => <RecommendationsCombosMobile {...props} IconFactory={RecommendationsIconFactory} />;

const combos = getCombos(Component);

export default combos;
