import React from 'react';
import { arrayOf, string, shape, func } from 'prop-types';
import classnames from 'classnames';
import CarouselFree, { CarouselFreeSlide } from '@andes/carousel-free';
import { trackEvent } from '../../lib/tracking';
import componentEnhance from '../../lib/component-enhance';
import IconFactory from '../icons/factory-builder';
import StyledLabel from '../styled-label';

const namespace = 'ui-vpp-carousel-pills';

const onClick = (pill, pillOnClick) => () => {
  pillOnClick();
  if (pill?.track) {
    trackEvent(pill.track);
  }
};

const CarouselPills = ({ className, pills, pillOnClick, id, ariaLabel }) => {
  const renderPill = pill => {
    const { text, values, text_icon } = pill?.label || {};
    return (
      <CarouselFreeSlide className={`${namespace}__slide`} key={id}>
        {pill?.label && (
          <StyledLabel
            className="ui-vpp-coupons__pill"
            key={text}
            as="p"
            onClick={onClick(pill, pillOnClick)}
            {...pill.label}
            text={IconFactory(text_icon)}
          >
            {componentEnhance.jsx(text, values)}
          </StyledLabel>
        )}
      </CarouselFreeSlide>
    );
  };

  return (
    <CarouselFree
      className={classnames(namespace, `${namespace}__carousel`, className)}
      srLabel={ariaLabel}
      pagination={false}
      arrowsVisible={false}
      arrows={false}
      autoplay={false}
      spacing={0}
      lazyload={false}
    >
      {pills.map(renderPill)}
    </CarouselFree>
  );
};

CarouselPills.propTypes = {
  ariaLabel: string,
  pills: arrayOf(
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string,
      onClick: func,
      text_icon: shape({
        id: string,
        size: string,
      }).isRequired,
    }),
  ),
  pillOnClick: func,
  className: string,
  id: string.isRequired,
};

CarouselPills.defaultProps = {
  ariaLabel: '',
  action: null,
  pillOnClick: null,
  pills: null,
  children: null,
  className: null,
  subtitles: null,
};

export default React.memo(CarouselPills);
