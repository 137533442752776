import { useCallback } from 'react';
import { trackQuantityEvent } from '../../../../lib/tracking';
import { parseBulkQuantity } from '../../../../utils/formatBulkQuantity';
import customOptions from '../../../../utils/quantity-custom-options';

const useQuantitySelectorMobile = (quantitySelector, setQuantity) => {
  const { custom_options } = quantitySelector?.selector?.template ?? {};
  const hasCustomOptions = customOptions.has(custom_options);
  const yieldValue = quantitySelector?.selector?.template?.yield;
  const hasBulkCustomOptions = customOptions.hasBulk(yieldValue, custom_options);
  const bulkSale = Boolean(yieldValue) && !hasBulkCustomOptions;

  const onSetQuantity = useCallback((q, { trackSourceElement }) => {
    setQuantity(q);
    if (bulkSale || hasBulkCustomOptions) {
      trackQuantityEvent(quantitySelector.track, parseBulkQuantity(q, yieldValue));
    } else {
      if (hasCustomOptions) {
        // Request from the Product Team to track whether the quantity was changed by input or by clicking on the list when priced by quantity
        const { track } = quantitySelector;
        track.melidata_event = {
          ...track.melidata_event,
          event_data: {
            ...track.melidata_event.event_data,
            selected_from: trackSourceElement,
          },
        };
      }
      trackQuantityEvent(quantitySelector.track, q);
    }
  }, [quantitySelector, setQuantity, bulkSale, hasBulkCustomOptions, yieldValue, hasCustomOptions]);

  const minimumQuantity = bulkSale ? yieldValue : quantitySelector?.minimum_quantity || 1;

  return {
    onSetQuantity,
    minimumQuantity,
    bulkSale,
    yieldValue,
    hasCustomOptions,
    hasBulkCustomOptions,
    custom_options,
  };
};

export default useQuantitySelectorMobile;
