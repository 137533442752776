import React from 'react';
import classnames from 'classnames';
import { namespace, quantityListPropTypes } from './model';
import QuantityListItem from './quantity-list-item';
import QuantityMoreButton from './quantity-more-button';
import { getLabelElement } from '../../helpers/get-label-element';
import { getOffsetIndex } from '../../helpers/get-offset-index';
import { isSelectedItem } from '../../helpers/is-selected-item';
import { getQuantityPickerRowsLength } from '../../helpers/get-quantity-picker-rows-length';

const QuantityList = ({
  label,
  length,
  min,
  max,
  more = null,
  selected,
  onClick,
  onShowInput,
  yieldValue = null,
  customOptions = null,
  hasBulkCustomOptions = false,
}) => {
  const hasOptions = !!(label.options && label.options.length > 0);
  const hasVolumeDiscount = !!label.volume_discount_options;
  const minimumQuantity = !min ? 1 : min;
  const final_length = getQuantityPickerRowsLength(
    !hasOptions,
    hasVolumeDiscount,
    label?.volume_discount_options?.length,
    length,
    minimumQuantity,
  );

  const bulkSale = Boolean(yieldValue) || hasBulkCustomOptions;
  const items = [];
  const onQuantityClick = (idx, m) => idx <= m && onClick(idx, { trackSourceElement: 'list' });

  const quantityIndex = hasOptions || hasBulkCustomOptions ? 1 : minimumQuantity;
  const offset = minimumQuantity - 1;

  for (let index = quantityIndex; index <= final_length; index += 1) {
    const { quantity, amount } = customOptions ? customOptions[index - 1] : {};
    const offsetIndex = getOffsetIndex(index, bulkSale, yieldValue, offset, quantity?.value, hasOptions);
    const isSelected = isSelectedItem(bulkSale, offsetIndex, selected);
    const labelElement = getLabelElement(label, index, hasOptions, hasBulkCustomOptions, customOptions);

    items.push(
      <QuantityListItem
        key={index}
        index={index}
        offsetIndex={offsetIndex}
        isSelected={isSelected}
        max={max}
        onQuantityClick={onQuantityClick}
        customOptions={customOptions ? { quantity, amount } : null}
        labelElement={labelElement}
      />,
    );
  }

  if (more && (bulkSale ? max > length * yieldValue : max > length)) {
    items.push(<QuantityMoreButton more={more} onShowInput={onShowInput} customOptions={customOptions} />);
  }
  return (
    <>
      <ul className={classnames(`${namespace}__list`, { [`${namespace}__list--options`]: hasOptions })}>{items}</ul>
    </>
  );
};

QuantityList.propTypes = quantityListPropTypes;

export default React.memo(QuantityList);
