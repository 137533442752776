import { withBoxComponent, MODAL } from '../../../../components/box-component/with-box-component.mobile';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import Box from '../../../../components/box-component/box';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';

const FEATURE_NAME = 'returns_box';

const Returns = withBoxComponent(MODAL)(Box);

const mapStateToProps = ({ components: { [FEATURE_NAME]: returns } }) => ({ ...returns });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

export default enhance(Returns);
