import React from 'react';
import classnames from 'classnames';
import { namespace, quantityMoreButtonPropTypes } from './model';

const QuantityMoreButton = ({ more, onShowInput, customOptions = null }) => (
  <li key="more" className={classnames(`${namespace}__item`, { [`${namespace}__item--last`]: !!customOptions })}>
    <button
      type="button"
      data-testid="more-button"
      onClick={e => {
        e.preventDefault();
        onShowInput(true);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onShowInput(true);
        }
      }}
    >
      {more}
    </button>
  </li>
);

QuantityMoreButton.propTypes = quantityMoreButtonPropTypes;

export default QuantityMoreButton;
