/* eslint-disable no-console */

/**
 * Get plugins data
 * @function getPluginsData
 * @param {Object} componentDefinitions
 * @returns {Object}
 */
const getPluginsData = componentDefinitions => {
  const keys = Object.keys(componentDefinitions);
  const getName = e => `${e.customName || e.name}(${e.pluginId || e.ownership || 'main'})`;

  return keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        mappers: componentDefinitions?.[key]?.mappers.map(getName).join(`, `) || null,
        hocs: componentDefinitions?.[key]?.hocs.map(getName).join(`, `) || null,
        component: componentDefinitions?.[key]?.component ? getName(componentDefinitions?.[key]?.componentData) : null,
        context: componentDefinitions?.[key]?.context ? getName(componentDefinitions?.[key]?.contextData) : null,
      },
    }),
    {},
  );
};

/**
 * Get plugin info
 * @function getInfo
 * @param {Function} plugin
 * @param {Object} matched
 * @returns {string}
 */
const getInfo = (error, matched) => {
  if (error) {
    return '❗ (rejected)';
  }
  return matched ? '✅ (match)' : '❌ (not match)';
};

/**
 * Get plugin name
 * @function getPluginName
 * @param {Function} fn
 * @returns {string}
 */
const getPluginName = fn => `${fn.id || 'plugin'}(${fn.ownership})`;
const getPluginId = fn => fn.id || fn.ownership;

const getNoMatchPlugins = (allPlugings, matchPlugins, pluginsWithErrors) =>
  allPlugings.filter(
    plugin => ![...matchPlugins, ...pluginsWithErrors].some(p => getPluginId(p) === getPluginId(plugin)),
  );

/**
 * Print by console the plugins applied to a feature
 * @example Print de ejemplo
 * Plugins aplicados a VariationsDesktop :
 * - VppPlugin ✅ (match)
 * - RefurbishedPlugin ❌ (not match)
 * - MShopsPlugin ❌ (not match)
 * - ApparelPlugin ❗ (rejected)
 * Resultados:
 * ┌─────────────────────────┬──────────────────────────────────┬──────┬─────────────────────────────┬──────────────────────┐
 * │ (index)                 │ mappers                          │ hocs │ component                   │ context              │
 * ├─────────────────────────┼──────────────────────────────────┼──────┼─────────────────────────────┼──────────────────────┤
 * │ main-component          │ 'mainMapper(Vpp)'                │ null │ 'VariationsDesktop()'       │ 'null'               │
 * │ picker                  │ 'pickerMapper(Vpp)'              │ null │ 'Picker(Vpp)'               │ 'null'               │
 * │ static-label-picker     │ 'staticLabelPickerMapper(Vpp)'   │ null │ 'StaticLabelPicker(Vpp)'    │ null                 │
 * │ dropdown-picker         │ 'dropdownPickerMapper(Vpp)'      │ null │ 'DropdownPicker(Vpp)'       │ null                 │
 * │ dropdown-picker-title   │ 'dropdownPickerTitleMapper(Vpp)' │ null │ 'DropdownPickerTitle(Vpp)'  │ null                 │
 * │ dropdown-picker-item    │ 'dropdownPickerItemMapper(Vpp)'  │ null │ 'DropdownPickerItem(Vpp)'   │ null                 │
 * │ default-picker          │ 'defaultPickerMapper(Vpp)'       │ null │ 'DefaultPicker(Vpp)'        │ null                 │
 * │ default-picker-title    │ 'defaultPickerTitleMapper(Vpp)'  │ null │ 'DefaultPickerTitle(Vpp)'   │ null                 │
 * │ default-picker-items    │ 'defaultPickerItemsMapper(Vpp)'  │ null │ 'DefaultPickerItems(Vpp)'   │ null                 │
 * │ default-picker-see-more │ null                             │ null │ 'DefaultPickerSeeMore(Vpp)' │ null                 │
 * └─────────────────────────┴──────────────────────────────────┴──────┴─────────────────────────────┴──────────────────────┘
 * @function debugPlugins
 * @param {Array} plugins
 * @param {Array} pluginsWithErrors
 * @param {Object} componentDefinitions
 * @param {Object} props
 */
const debugPlugins = (allPlugings, matchPlugins, pluginsWithErrors, componentDefinitions, featureName) => {
  const noMatchPlugins = getNoMatchPlugins(allPlugings, matchPlugins, pluginsWithErrors);
  console.log(`\nPlugins aplicados a ${featureName} :\n`);
  console.log(matchPlugins.map(fn => `- ${getPluginName(fn)} ${getInfo(false, true)}`).join(`\n`));
  console.log(noMatchPlugins.map(fn => `- ${getPluginName(fn)} ${getInfo(false, false)}`).join(`\n`));
  console.log(pluginsWithErrors.map(fn => `- ${fn.name} ${getInfo(true)}`).join(`\n`));
  console.log(`\nResultados:\n`);
  console.table(getPluginsData(componentDefinitions));
  console.log(`\n`);
};

export default debugPlugins;
