import { loadable } from 'nordic/lazy';
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import mapStateToProps from '../../../../components/recommendations/state-mapper-base';

const RecommendationsCarouselByType = loadable(() => import('../../../../components/recommendations/carousel-byType'));

const enhance = compose(
  withFeatureFlag(),
  connect(
    mapStateToProps(),
    null,
    'recos_carousel_by_type',
  ),
);

export default enhance(RecommendationsCarouselByType);
