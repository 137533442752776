import React from 'react';
import classnames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';
import { namespace, quantityListItemPropTypes } from './model';

const QuantityListItem = ({
  index,
  offsetIndex,
  isSelected,
  max,
  onQuantityClick,
  customOptions = null,
  labelElement,
}) => {
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onQuantityClick(index, max);
    }
  };
  return (
    <li
      key={index}
      className={classnames(`${namespace}__item`, {
        [`${namespace}__item--selected`]: isSelected,
        [`${namespace}__item--disabled`]: index > max,
        [`${namespace}__item--custom-options`]: customOptions?.quantity?.value,
      })}
    >
      <button
        type="button"
        data-testid="quantity-button"
        id={`${namespace}__item-button-${index}`}
        onClick={() => onQuantityClick(offsetIndex, max)}
        onKeyDown={handleKeyDown}
      >
        {customOptions ? (
          <div
            className={classnames(`${namespace}__item--label`, {
              [`${namespace}__item--label-selected`]: isSelected,
            })}
          >
            <span>{labelElement}</span>
            <MoneyAmount
              size={16}
              value={{ fraction: customOptions.amount.fraction, cents: customOptions.amount.cents ?? null }}
              suffix={customOptions.amount.suffix?.text}
              currencyId={customOptions.amount.currency_id}
              weight={isSelected ? 'semibold' : 'regular'}
              centsType="superscript"
            />
          </div>
        ) : (
          labelElement
        )}
      </button>
    </li>
  );
};

QuantityListItem.propTypes = quantityListItemPropTypes;

export default QuantityListItem;
